import React, { useRef, useEffect } from 'react';


const Faq = () => {
  const downloadRef = useRef(null);

  useEffect(() => {
    downloadRef?.current.click();

    setTimeout(() => {
      window.location.href = "/";
    }, 200);
  },[downloadRef]);
  return (
    <>
      <div className="pdf">
        <a ref={downloadRef} href="/pdfs/SEESAW_FAQ_2023.pdf" download="SEESAW_FAQ_2023"></a>
      </div>
    </>
  )
}

export default Faq;